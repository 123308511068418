import React from 'react';
import Login from './Login';

interface HeaderProps {
  toggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ toggleSidebar }) => {
  return (
    <header className="fixed top-0 left-0 w-full z-50 bg-white">
      <div className="container mx-auto flex justify-between items-center p-4">
        <div className="flex items-center">
          <button onClick={toggleSidebar} className="mr-4">
            {/* 3-dot icon */}
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M6 10a2 2 0 114 0 2 2 0 01-4 0zM10 16a2 2 0 100-4 2 2 0 000 4zM10 6a2 2 0 100-4 2 2 0 000 4z" />
            </svg>
          </button>
          <h1 className="text-2xl font-bold">Employee Portal</h1>
        </div>
        <Login />
      </div>
    </header>
  );
};

export default Header;