import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, getDocs, deleteDoc, doc } from 'firebase/firestore';

interface Message {
  id: string;
  name: string;
  phone: string;
  message: string;
}

const Inbox: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [expandedMessage, setExpandedMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const q = query(collection(db, 'contacts'));
      const querySnapshot = await getDocs(q);
      const fetchedMessages: Message[] = [];
      querySnapshot.forEach((doc) => {
        fetchedMessages.push({ id: doc.id, ...doc.data() } as Message);
      });
      setMessages(fetchedMessages);
      setError(null);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Failed to fetch messages. Please try again later.');
    }
  };

  const handleExpand = (id: string) => {
    setExpandedMessage(expandedMessage === id ? null : id);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'contacts', id));
      setMessages(messages.filter(message => message.id !== id));
      setDeleteError(null);
    } catch (error) {
      console.error('Error deleting message:', error);
      setDeleteError('Failed to delete the message. Please try again.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Inbox</h1>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
          <p>{error}</p>
        </div>
      )}
      {deleteError && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
          <p>{deleteError}</p>
        </div>
      )}
      {messages.length === 0 && !error ? (
        <p>No messages in the inbox.</p>
      ) : (
        <ul className="space-y-4">
          {messages.map((message) => (
            <li key={message.id} className="bg-white rounded-lg shadow-md p-4">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">{message.name}</h2>
                <button
                  onClick={() => handleExpand(message.id)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  {expandedMessage === message.id ? 'Collapse' : 'Expand'}
                </button>
              </div>
              {expandedMessage === message.id && (
                <div className="mt-4">
                  <p><strong>Phone:</strong> {message.phone}</p>
                  <p><strong>Message:</strong> {message.message}</p>
                  <button
                    onClick={() => handleDelete(message.id)}
                    className="mt-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Inbox;