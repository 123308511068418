import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {
  collection,
  query,
  getDocs,
  orderBy,
  limit,
  startAfter,
  getCountFromServer,
} from 'firebase/firestore';

interface Doctor {
  id: string;
  name: string;
  specialty: string;
}

const Doctors: React.FC = () => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const doctorsPerPage = 50;
  const [totalDoctors, setTotalDoctors] = useState<number>(0);

  useEffect(() => {
    const initialize = async () => {
      const coll = collection(db, 'doctors');
      const snapshot = await getCountFromServer(coll);
      const total = snapshot.data().count;
      setTotalDoctors(total);

      fetchDoctors(1);
    };
    initialize();
  }, []);

  const fetchDoctors = async (pageNumber: number) => {
    setLoading(true);
    try {
      let q;
      const doctorsRef = collection(db, 'doctors');
      const offset = (pageNumber - 1) * doctorsPerPage;

      if (pageNumber === 1) {
        q = query(doctorsRef, orderBy('name'), limit(doctorsPerPage));
      } else {
        const previousPageQuery = query(
          doctorsRef,
          orderBy('name'),
          limit(offset)
        );
        const previousPageSnapshot = await getDocs(previousPageQuery);
        const lastDoc =
          previousPageSnapshot.docs[previousPageSnapshot.docs.length - 1];

        q = query(
          doctorsRef,
          orderBy('name'),
          startAfter(lastDoc),
          limit(doctorsPerPage)
        );
      }

      const querySnapshot = await getDocs(q);
      const fetchedDoctors: Doctor[] = [];
      querySnapshot.forEach((doc) => {
        fetchedDoctors.push({ id: doc.id, ...doc.data() } as Doctor);
      });

      setDoctors(fetchedDoctors);
      setCurrentPage(pageNumber);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.error('Error fetching doctors:', error);
      setError('Failed to fetch doctors. Please try again later.');
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="loading-spinner"></div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  const totalPages = Math.ceil(totalDoctors / doctorsPerPage);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Doctors</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">ID</th>
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Specialty</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {doctors.map((doctor) => (
              <tr key={doctor.id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">{doctor.id}</td>
                <td className="py-3 px-6 text-left">{doctor.name}</td>
                <td className="py-3 px-6 text-left">{doctor.specialty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => fetchDoctors(index + 1)}
            className={`px-3 py-1 mx-1 ${
              currentPage === index + 1
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200'
            } rounded`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Doctors;