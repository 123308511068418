import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  FaInbox,
  FaUsers,
  FaChevronLeft,
  FaUserMd,
} from 'react-icons/fa';

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  return (
    <aside
      className={`fixed top-0 left-0 h-screen pt-20 transition-all duration-300 bg-white border-r border-gray-200 z-40 ${
        isOpen ? 'w-64' : 'w-20'
      } ${
        isOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'
      }`}
    >
      {/* Mobile Collapse Button */}
      <div className="absolute top-4 right-4 md:hidden">
        <button onClick={toggleSidebar}>
          <FaChevronLeft />
        </button>
      </div>

      <div className="h-full px-3 pb-4 overflow-y-auto">
        <ul className="space-y-2 font-medium">
          <li>
            <NavLink
              to="/inbox"
              className="flex items-center p-2 rounded-lg hover:bg-gray-100"
            >
              <FaInbox className="w-5 h-5 text-gray-500" />
              {isOpen && <span className="ml-3">Inbox</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/patients"
              className="flex items-center p-2 rounded-lg hover:bg-gray-100"
            >
              <FaUsers className="w-5 h-5 text-gray-500" />
              {isOpen && <span className="ml-3">Patients</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/doctors"
              className="flex items-center p-2 rounded-lg hover:bg-gray-100"
            >
              <FaUserMd className="w-5 h-5 text-gray-500" />
              {isOpen && <span className="ml-3">Doctors</span>}
            </NavLink>
          </li>
          {/* Add any additional navigation items here */}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;